.red{
	color: red;
}

.table-custom{
	width: auto;
}

.head-item, .head-item p {
	font-weight: 600;
}

td.head-item p {
	margin-bottom: 0;
}