body{
    font-size: 14px;
}
.section {
    clear: both;
    padding-top: 10px;
}

.section hr{
    margin-bottom: 5px;
}

.section label:first-child{
    font-weight: 600;
}

.floatLeft {
    float: left;
}

.tense{
    width: 340px;
}
.checker {
/*    display: flex;
    justify-content: center;*/
}

.configuration p input {
    margin-left: 10px;
}

input[type="checkbox"]{
    margin-right: 5px;
}

#verbCount{
    width: 50px;
    height: 40px;
    padding-left: 5px;
}

.smallButton {
    margin-left: 10px;
    padding: 0.25rem;
    font-size: 0.8rem;
    border-radius: 0.2rem;
}

.basic-multi-select {
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
}

@media (min-width: 768px) {
    .basic-multi-select {
        width: 50%;
        padding-top: 10px;
        padding-left: 50px;
    }
}

.game-verbs-select {
    padding-bottom: 20px;
}
.paddingTop32 {
    padding-top: 2em;
}
.paddingBottom48 {
    padding-bottom: 3em;
}

.gameForm td.gameColumn-1 {
    white-space: nowrap;
}

.gameForm td.gameColumn-2 {
    width: 370px;
    padding: 10px;
}

.gameForm td.gameColumn-3 {
    min-width: 100px;
}

@media (min-width: 768px) {
    .gameForm td.gameColumn-1 {
        text-align: right;
        width: 100px;
    }

    .gameForm td.gameColumn-13{
        padding-left: 100px;
    }
}

.contactUs form .mbr-section-btn {
    text-align: center;
    width: 100%;
}

table.statistic{
    width: auto;
}

table.statistic td {
    text-align: center;
}