.select {
    width: 300px;
}

hr {
    margin-top: 0px;
}

fieldset {
    margin: 8px;
    border: 1px solid silver;
    padding: 8px;
    border-radius: 4px;
    min-width: 140px;
    min-height: 80px;
}

legend {
    padding: 2px;
    float: none;
    width: fit-content;
    font-size: 14px;
}

.clearBoth{
    clear: both;
}

.floatLeft{
    float: left;
}

.pronounsLabel{
    //font-size: 12px;
    color: silver;
}

td.pronounsLabel{
    padding-right: 30px;
}

.select-inline{
    padding-top: 8px;
    padding-left: 100px;
}