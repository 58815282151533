input.testBox, select.testBox {
	border: 0px;
	border-bottom: 1px solid black;
}

.green{
	color: green;
}

.stripedRed {
	color: red;
	text-decoration: line-through;
}

.input-container {
	position: relative;
}

.input-container .input-dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: -40px;
	background-color: white;
	text-align: center;
}

.input-container .input-dropdown .btn{
	padding: 2px;
	margin: 2px;
	width: 20px;
}