.nav-left.nav-tabs {
	border-right: 1px solid #dee2e6;
	border-bottom: 1px solid transparent;
	min-width: 290px;
}

	.nav-left.nav-tabs .nav-link.active {
		border-top-color: rgb(222, 226, 230);
		border-right-color: rgb(255, 255, 255);
		border-bottom-color: rgb(222, 226, 230);
		border-left-color: rgb(222, 226, 230);
	}

	.nav-left.nav-tabs .nav-link:hover, .nav-left.nav-tabs .nav-link:focus {
		border-top-color: rgb(233, 236, 239);
		border-right-color: transparent;
		border-bottom-color: rgb(233, 236, 239);
		border-left-color: rgb(233, 236, 239);
	}

.nav-link.active {
	font-weight: 600;
}

.nav-left.nav-tabs .nav-link {
	width: 100%;
	text-align: left;
	white-space: nowrap;
}

.nav-left div {
	width: 100%;
}

.nav-tabs {
	display: none;
}

@media (min-width: 768px) {
	.nav-tabs {
		display: block;
	}

	.tabSelect {
		display: none;
	}
}
