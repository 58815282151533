h4, h5, h6 {
	font-family: 'Jost', sans-serif;
	font-size: 1.2rem;
	line-height: 1.5;
	font-weight: bold !important;
}

h4 {
	margin-bottom: 1.5rem !important;
}
