/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
    font-family: Jost, sans-serif;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-size: 17.6px;
    font-stretch: 100%;
    font-weight: 600;
}

body, p {
    font-family: 'Jost', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
}

blockquote {
    padding: 1em 3em;
}

blockquote p {
    line-height: 1;
}